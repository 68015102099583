<template>
  <div
    v-if="multicorderEnabled || uploadEnabled"
    class="row d-flex justify-content-center pt-5"
  >

    <!--Upload Button-->
    <button
      v-if="uploadEnabled"
      type="button"
      :class="formError ? 'btn-danger' : 'btn-primary'"
      class="btn mx-2"
      :disabled="disabled"
    >
      {{ uploadButtonText }}
    </button>

    <!--Multicorder Button-->
    <button
      v-if="multicorderEnabled && !inlineSROpen"
      type="button"
      :class="formError ? 'btn-danger' : 'btn-primary'"
      class="btn mx-2"
      :disabled="disabled"
      v-on:mouseover="$emit('disableDropzone')"
      v-on:mouseleave="$emit('enableDropzone')"
      @click.prevent="openSR"
    >
      {{ $t("ASSET_RESERVATION.BUTTONS.RECORD") }}
    </button>

    <!--Multicorder Modal-->
    <div v-if="!inlineSR" id="modal-container">
      <b-modal
        v-if="multicorderEnabled"
        :id="id + '-record-modal'"
        size="lg"
        hide-header
        hide-footer
      >
        <div class="mb-0">
          <MulticorderUI
            :recorderSettings="recorderSettings"
            @close-modal="$bvModal.hide(id + '-record-modal')"
            @addFile="addFile"
          >
          </MulticorderUI>
        </div>
      </b-modal>
    </div>

    <!--Multicord Inline-->
    <div
      v-if="inlineSR && inlineSROpen"
      class="mb-0 w-100"
      v-on:mouseover="$emit('disableDropzone')"
      v-on:mouseleave="$emit('enableDropzone')"
    >
      <MulticorderUI
        :recorderSettings="recorderSettings"
        @close-modal="$emit('close-inline-sr')"
        @addFile="addFile"
      >
      </MulticorderUI>
    </div>

  </div>
</template>

<script>
import MulticorderUI from '../multicorder/MulticorderUI';

export default {
  Name: "ArDropzoneButtons",

  props: {
    id: { type: String, required: true },
    fileCount: { type: Number, required: true },
    recorderSettings: { type: Object, required: true },
    uploadEnabled: { type: Boolean, required: true },
    multicorderEnabled: { type: Boolean, required: true },
    type: { type: String, required: true },
    formError: { type: Boolean, required: false },
    disabled: { type: Boolean, required: true },
    inlineSR: { type: Boolean, required: false, default: false },
    inlineSROpen: { type: Boolean, required: true }
  },

  components: {
    MulticorderUI
  },

  methods: {
    addFile: function(file) {
      this.$bvModal.hide(this.id + '-record-modal');
      this.$emit('close-inline-sr');
      this.$emit('addFile',file)
    },
    openSR: function() {
      if (!this.inlineSR) {
        this.$bvModal.show(this.id + '-record-modal')
      } else {
        this.$emit('inline-sr-opened');
      }
    }
  },

  computed: {
    uploadButtonText: function() {
      let translationScope =
        this.fileCount == 1 ? "SINGULAR" : "PLURAL";
      let i18nString =
        "ASSET_RESERVATION.BUTTONS.UPLOAD_" +
        this.type +
        "_" +
        translationScope;

      return `${this.$t(i18nString)}`;
    }
  }
}
</script>