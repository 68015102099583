class LoadingCompleteHelper {

  //Assesses whether page loading has been completed by it's page loading properties
  static loadingComplete(pageLoader, error) {
    if (
      pageLoader.componentsCompleted >=
        pageLoader.componentsRequired &&
      error.active == false
    ) {
      return true;
    } else {
      return false;
    }
  }

}

export default LoadingCompleteHelper;