<template>
  <div class="my-10 w-100 d-flex justify-content-center">
    <b-spinner
      variant="primary"
      label="loading"
      :style="style"
    >
    </b-spinner>    
  </div>

</template>

<script>
export default {
  name: "GenericComponentLoader",

  props: {
    large: { type: Boolean, required: false, default: false }
  },

  computed: {
    style: function() {
      return (this.large) ? "width: 3rem; height: 3rem" : "width: 2rem; height: 2rem"
    }
  }
};
</script>
