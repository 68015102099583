<template>
  <div
    class="row d-flex mt-5 pt-0 mx-3"
    :class="{ 'justify-content-center' : onlyCapture, 'justify-content-between' : !onlyCapture }"
  >
    <div
      v-if="recorderSettings.record"
      class="pt-2 col-3 text-left"
    >
      <a
        href="javascript:void(0)"
        @click="$emit('toggleMuted')"
        v-b-tooltip.hover.bottom
        :title="calculatedMuteTooltip"
      >
        <i
          class="fa icon-lg"
          :class="{ 'fas fa-microphone text-muted text-hover-danger pl-2 pr-2' : !isMuted, 'fas fa-microphone-slash text-danger text-hover-muted pr-1' : isMuted }"
        >
        </i>
      </a>
    </div>

    <div
      class="pt-0 col-6 text-center"
      v-if="recorderSettings.record"  
    >
      <a
        href="javascript:void(0)"
        @click="$emit('videoRecord')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.RECORD')"
      >
        <i
          class="fa fas fa-dot-circle text-danger" style="font-size:3rem !important"
        >
        </i>
      </a>
    </div>

    <div
      class="pt-2 col-3"
      :class="{ 'text-center' : onlyCapture, 'text-right' : !onlyCapture }"
    >
      <a
        v-if="recorderSettings.capture"  
        href="javascript:void(0)"
        class="pt-2 col-3 text-right"
        @click="$emit('videoSnapshot','video')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.SNAPSHOT')"
      >
        <i class="fa icon-xl fas fa-camera pr-2 text-muted text-hover-warning"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreRecord",

  props: {
    isMuted: { type: Boolean, required: true },
    recorderSettings: { type: Object, required: true }
  },

  computed: {
    calculatedMuteTooltip: function() {
      return (this.isMuted) ? `${this.$t("MULTICORDER.CONTROLS.UNMUTE")}` : `${this.$t("MULTICORDER.CONTROLS.MUTE")}`
    },
    onlyCapture: function() {
      return (this.recorderSettings.capture && !this.recorderSettings.record) ? true : false;
    },
    onlyRecord: function() {
      return (!this.recorderSettings.capture && this.recorderSettings.record) ? true : false;
    },
    allMethods: function() {
      return (this.recorderSettings.capture && this.recorderSettings.record) ? true : false;
    }
  }
}
</script>