<template>
  <InputDropdown
    class="mt-5 mx-5"
    v-show="view == 'video'"
    :options="videoSourceList"
    :model="videoSource"
    label="Select video input"
    optionLabel="text"
    value="value"
  >
  </InputDropdown>
</template>

<script>
import InputDropdown from "../../../GenericDropdown";

export default {
  name: "DeviceInput",

  props: {
    videoSource: { type: Object, required: false },
    view: { type: String, required: true },
    videoSourceList: { type: Array, required: true }
  },

  components: {
    InputDropdown
  }
}
</script>