<template>
  <div>
    <div class="row">
      <InputGenericARDropzone
        :class="(wholeWidth ? 'w-100' : 'w-15') + ' mx-5'"
        :id="'integrated-dropzone-' + idx"
        :ref="'arDropzone-' + idx"
        :assetReservation="assetReservation"
        :arDropzoneOptions="arDropzoneOptions"
        :disabledPreviews="true"
        :formError="formError"
        :loadingComplete="loadingComplete"
        :galleryCount="galleryCount"
        :dropzoneDisabled="calculatedDropzoneDisabled"
        :inlineSR="inlineSR"
        @asset_reservation_created="createdAssetReservation($event)"
        @uploaded_file="uploadedAFile($event)"
        @update_file_progress="updateProgressOfFile($event)"
      >
      </InputGenericARDropzone>
    </div>
    <div class="row" v-if="loadingComplete">
      <InputGenericThumbGallery
        v-if="projectAssets.length > 0 || previews.length > 0"
        class="w-100 mb-7"
        classStyle="sm"
        :removable="removable"
        :canAddCaption="canAddCaption"
        :projectAssets="projectAssets"
        :previews="previews"
        ref="thumbGallery"
        @remove_project_asset="removeProjectAsset($event)"
        @gallery_count_changed="galleryCount = $event"
        @thumb_deleted="deleteAssetReservation"
        @caption_changed="$emit('attachment_caption_changed', $event)"
        @thumb_cancelled="cancelUploadedFile"
      >
      </InputGenericThumbGallery>
    </div>
  </div>
</template>

<script>
import { RETURN_SUPPORTED_ASSET_TYPE } from "@/core/services/store/system/asset.module";

import LoadingCompleteHelper from "../../helpers/LoadingCompleteHelper";

import InputGenericThumbGallery from "./generic_integrated_dropzone/GenericThumbGallery";
import InputGenericARDropzone from "./generic_integrated_dropzone/GenericARDropzone";

export default {
  name: "GenericIntegratedDropzone",

  props: {
    idx: { type: Number, required: true },
    assetReservation: { type: Object, required: true },
    overrideARDropzoneOptions: { type: Object, required: false },
    element: {
      type: Object,
      required: false,
      default: function() {
        return { previews: [] };
      }
    },
    projectAssets: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    wholeWidth: { type: Boolean, required: false },
    formError: { type: Boolean, required: false },
    removable: { type: Boolean, required: false },
    canAddCaption: { type: Boolean, required: false },
    initialPreviews: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    inlineSR: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      galleryCount: 0,
      hovering: null,
      previews: [],
      initialProjectAssets: [],
      removedProjectAssets: [],
      arDropzoneOptions: {
        supportedMimeTypes: {},
        supportedMimeSizes: {},
        supportedMethods: [],
        supportedFileSize: 0,
        supportedFileCount: 0
      },
      pageLoader: {
        componentsRequired: 1,
        componentsCompleted: 0
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  components: {
    InputGenericThumbGallery,
    InputGenericARDropzone
  },

  created() {
    this.initialProjectAssets = this.projectAssets;
    if (this.initialPreviews != undefined) {
      this.previews = this.initialPreviews;
    }
  },

  mounted() {
    if (this.overrideARDropzoneOptions != undefined) {
      this.arDropzoneOptions = this.overrideARDropzoneOptions;
    } else {
      this.fetchSupportedFileTypes();
    }
  },

  methods: {
    fetchSupportedFileTypes() {
      let payload = {
        scope: this.assetReservation.scope,
        directory: this.assetReservation.data.directory,
        params: {}
      };

      if (this.assetReservation.data.activityId != undefined) {
        payload.params.activity_id = this.assetReservation.data.activityId;
        payload.activityId = this.assetReservation.data.activityId;
      }

      if (this.assetReservation.data.questionId != undefined) {
        payload.params.question_id = this.assetReservation.data.questionId;
      }

      if (this.assetReservation.data.system_answer_type_id != undefined) {
        payload.params.system_answer_type_id =
          this.assetReservation.data.system_answer_type_id;
      }

      this.$store.dispatch(RETURN_SUPPORTED_ASSET_TYPE, payload).then((res) => {
        if (!this._.includes(res.methods, 5)) {
          if (!this._.includes(res.methods, 6)) {
            delete res.types.video;
          }
        }
        this.arDropzoneOptions.supportedMimeTypes = res.types;
        this.arDropzoneOptions.supportedMimeSizes = res.max_size;
        this.arDropzoneOptions.supportedMethods = res.methods;
        this.arDropzoneOptions.supportedFileCount = res.limit;
        this.pageLoader.componentsCompleted++;
      });
    },
    uploadedAFile($event) {
      if (this.element) {
        this.element.previews.push($event);
        if (this.previews !== this.element.previews) {
          this.previews.push($event);
        }
      } else {
        this.previews.push($event);
      }

      this.$emit("previews_updated", this.previews);
    },
    updateProgressOfFile($event) {
      let assetReservationId = $event.assetReservationId;
      let fileIndex = this.previews.findIndex(function(stim) {
        return stim.assetReservationId == assetReservationId;
      });
      this.previews[fileIndex].progress = $event.progress;
    },
    fileRemovedToast(name) {
      this.$root.$bvToast.toast(
        `${name} ${this.$t("TOASTS.FILE_UPLOADED.REMOVED_MESSAGE")}`,
        {
          title: `${this.$t("TOASTS.FILE_UPLOADED.REMOVED_TITLE")}`,
          autoHideDelay: 5000,
          variant: "warning"
        }
      );
    },
    deleteAssetReservation($event) {
      this.previews = this.previews.filter(function(stim) {
        return stim.assetReservationId !== $event.assetReservationId;
      });

      this.$emit("asset_reservation_deleted", $event.assetReservationId);
      this.fileRemovedToast($event.filename);
    },
    cancelUploadedFile($event) {
      let arDZ = this.$refs["arDropzone-" + this.idx];
      let id = "input-choice-stimulus-" + this.idx;
      if (arDZ !== undefined) {
        const files = arDZ.$refs["ref-" + id].getUploadingFiles();
        arDZ.$refs["ref-" + id].removeFile(files[0]);
        arDZ.processingQueue.size--;
        arDZ.processingQueue.processing = false;
        this.previews = this.previews.filter(function(stim) {
          return stim.assetReservationId !== $event.assetReservationId;
        });
        this.$emit("asset_reservation_deleted", $event.assetReservationId);
        this.fileRemovedToast($event.filename);
      }
    },
    createdAssetReservation($event) {
      this.$emit("asset_reservation_created", $event);
    },
    removeProjectAsset($event) {
      this.removedProjectAssets.push($event);
      this.$emit("remove_project_asset", $event);
      this.fileRemovedToast("");
    },
    clearPreviews: function() {
      this.previews = [];
      this.element.previews = [];
    }
  },

  watch: {
    previewProp: {
      handler(value) {
        this.previews = value;
      },
      immediate: true
    },
    projectAssets: {
      handler(value) {
        this.initialProjectAssets = value;
      },
      immediate: true,
      deep: true
    }
  },

  computed: {
    previewProp: function() {
      return this.element ? this.element.previews : [];
    },
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },
    calculatedDropzoneDisabled: function() {
      let initialProjectAssets = 0;
      if (this.initialProjectAssets !== undefined) {
        initialProjectAssets = this.initialProjectAssets.length;
      }
      let galleryAssets =
        this.previews.length +
        (initialProjectAssets - this.removedProjectAssets.length);

      let maxFiles = this.arDropzoneOptions.supportedFileCount;

      return maxFiles == 0 || galleryAssets < maxFiles || galleryAssets == 0
        ? false
        : true;
    }
  }
};
</script>
