<template>
  <div class="row d-flex justify-content-between mt-5 pt-0 mx-3">
    <div class="pt-2 col-3 text-left">
      <a
        href="javascript:void(0)"
        @click="$emit('togglePlayerMuted')"
        v-b-tooltip.hover.bottom
        :title="calculatedSoundTooltip"
      >
        <i
          class="fa icon-lg"
          :class="{ 'fas icon-xl fa-volume-off text-muted text-hover-danger' : !isPlayerMuted, 'fas fas fa-volume-mute text-danger text-hover-muted' : isPlayerMuted }"
        >
        </i>
      </a>
    </div>

    <div class="pt-2 col-6 text-center">
      <a
        href="javascript:void(0)"
        @click="$emit('downloadRecording')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.DOWNLOAD')"
      >
        <i
          class="fas fa-download text-muted text-hover-warning px-4" style="font-size:1.7rem !important"
        >
        </i>
      </a>
      
      <a
        href="javascript:void(0)"
        @click="$emit('playRecording')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.PLAY')"
      >
        <i
          class="fas fa-play text-muted text-hover-primary px-4" style="font-size:1.7rem !important"
        >
        </i>
      </a>

      <a
        href="javascript:void(0)"
        @click="$emit('completeRecording')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.SUBMIT')"
      >
        <i
          class="fas fa-check text-muted text-hover-success px-4" style="font-size:1.7rem !important"
        >
        </i>
      </a>
    </div>
    
    <div class="pt-2 col-3 text-right">
      <a
        href="javascript:void(0)"
        @click="$emit('deleteRecording')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.CANCEL')"
      >
        <i class="fa icon-lg fas fa-trash-alt pr-2 text-muted text-hover-danger"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostRecord",

  props: {
    isPlayerMuted: { type: Boolean, required: true }
  },

  computed: {
    calculatedSoundTooltip: function() {
      return (this.isPlayerMuted) ? `${this.$t("MULTICORDER.CONTROLS.NOSOUND")}` : `${this.$t("MULTICORDER.CONTROLS.SOUND")}`
    }
  }
}
</script>