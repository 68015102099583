<template>
  <div>
    <b-form-group
      :id="id + 'group'"
      :label="computedLabel"
      :label-for="id"
      :label-sr-only="displayLabel"
      class="w-100 mb-0"
    >
      <vue-dropzone
        :ref="'ref-' + id"
        :id="id"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        v-show="!hidden"
        :class="formError ? 'drop-file-invalid' : ''"
        @vdropzone-file-added="fileAdded"
        @vdropzone-removed-file="fileRemoved"
        @vdropzone-upload-progress="fileUploadingProgress"
        @vdropzone-success="fileUploaded"
        @vdropzone-error="fileError"
        @vdropzone-canceled="fileCancelled"
      >
        <div class="dropzone-custom-content">
          <div v-if="loadingComplete">

            <ArDropzoneIcons
              v-if="!inlineSROpen"
              :mimeTypes="arDropzoneOptions.supportedMimeTypes"
              :mimeSizes="arDropzoneOptions.supportedMimeSizes"
            >
            </ArDropzoneIcons>

            <ArDropzoneText
              v-if="!computedDisabled && !inlineSROpen"
              :fileCount="this.arDropzoneOptions.supportedFileCount"
              :uploadEnabled="uploadEnabled"
              :multicorderEnabled="multicorderEnabled"
              :type="calculatedType"
            >
            </ArDropzoneText>

            <ArDropzoneButtons
              v-if="!computedDisabled"
              :id="id"
              :fileCount="this.arDropzoneOptions.supportedFileCount"
              :recorderSettings="{ record: supportsScreenRecord, capture: supportsScreenCapture, methods: arDropzoneOptions.supportedMethods }"
              :uploadEnabled="uploadEnabled"
              :multicorderEnabled="multicorderEnabled"
              :type="calculatedType"
              :formError="formError"
              :disabled="computedDisabled"
              :inlineSR="inlineSR"
              :inlineSROpen="inlineSROpen"
              @enableDropzone="$refs['ref-' + id].enable()"
              @disableDropzone="$refs['ref-' + id].disable()"
              @addFile="manuallyAddFile"
              @inline-sr-opened="inlineSROpen = true"
              @close-inline-sr="inlineSROpen = false"
            >
            </ArDropzoneButtons>

            <div class="custom-validation-failure pt-10" v-if="computedDisabled">
              {{ $t("MULTICORDER.DROPZONE.LIMIT_REACHED") }}
            </div>
            
          </div>
          <ComponentLoader v-else></ComponentLoader>
        </div>
      </vue-dropzone>
    </b-form-group>
    <div class="custom-validation-failure" v-if="formError">
      {{ $t("FORMS.GENERIC_ERRORS.REQUIRED") }}
    </div>
  </div>
</template>

<script>
import {
  CLEAR_ASSET_ERRORS,
  POPULATE_SIGNED_URL
} from "../../../vuex/system/asset.module";

import GenericInputHelpers from "../../../helpers/GenericInputHelpers";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ComponentLoader from "../../generic-displays/GenericComponentLoader";
import ArDropzoneIcons from "./ar_dropzone/Icons";
import ArDropzoneText from "./ar_dropzone/Text";
import ArDropzoneButtons from "./ar_dropzone/Buttons";

export default {
  name: "GenericARDropzone",

  props: {
    label: { type: String, required: false, default: "" },
    id: { type: String, required: true },
    formError: { type: Boolean, required: false },
    assetReservation: { type: Object, required: true },
    arDropzoneOptions: { type: Object, required: true },
    dropzoneDisabled: { type: Boolean, required: true },
    hidden: { type: Boolean, required: false },
    disabledPreviews: { type: Boolean, required: false },
    loadingComplete: { type: Boolean, required: true },
    inlineSR: { type: Boolean, required: false, default: false }
  },

  components: {
    vueDropzone: vue2Dropzone,
    ComponentLoader,
    ArDropzoneIcons,
    ArDropzoneText,
    ArDropzoneButtons
  },

  data: function() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        clickable: true,
        disabled: true,
        autoProcessQueue: false,
        uploadMultiple: false,
        parallelUploads: 1,
        acceptedFiles: null,
        method: "put",
        maxFilesize: null,
        maxFiles: 0,
        addRemoveLinks: true,
        createImageThumbnails: true,
        thumbnailMethod: "crop",
        thumbnailHeight: 200,
        thumbnailWidth: 150,
        timeout: 0,
        sending: function(file, xhr) {
          var _send = xhr.send;
          xhr.send = function() {
            _send.call(xhr, file);
          };
        }
      },
      processingQueue: {
        size: 0,
        processing: false,
        assetReservationId: null
      },
      width: null,
      height: null,
      temporaryLog: {},
      inlineSROpen: false
    };
  },

  created() {
    this.dropzoneOptions.maxFilesize = this.arDropzoneOptions.supportedFileSize;
    this.dropzoneOptions.disable = this.dropzoneDisabled;
    if (this.disabledPreviews) {
      this.dropzoneOptions.previewsContainer = false;
    }
    if (this.arDropzoneOptions.supportedFileCount == 0) {
      this.dropzoneOptions.maxFiles = null;
    } else {
      this.dropzoneOptions.maxFiles = this.arDropzoneOptions.supportedFileCount;
    }
  },

  methods: {
    getFileDimensions(file) {
      return new Promise(resolve => {
        var fr = new FileReader();
        fr.onload = function() {
          var img = new Image();

          img.onload = function() {
            resolve({
              height: img.height,
              width: img.width
            });
          };

          img.src = fr.result;
        };

        fr.readAsDataURL(file);
      });
    },
    clearErrors: function() {
      this.error = "";
      this.$store.dispatch(CLEAR_ASSET_ERRORS);
    },
    checkValidType(file) {
      let types = Object.keys(this.arDropzoneOptions.supportedMimeTypes);
      let fileCat = this._.split(file.type, "/", 1)[0];
      return this._.includes(types, fileCat);
    },
    getFileType(file) {
      if (file.type.match("video.*")) return 1;
      if (file.type.match("image.*")) return 2;
      if (file.type.match("audio.*")) return 3;
      if (file.type.match("text.*")) return 4;
      if (file.type.match("application.*")) return 5;
    },
    fileAdded(file) {
      this.clearErrors();
      let type = this.getFileType(file);
      if (type == 2) {
        this.getFileDimensions(file).then(dimensions => {
          this.width = dimensions.width;
          this.height = dimensions.height;
          this.processingQueue.size++;
        });
      } else {
        this.processingQueue.size++;
      }
    },
    fileRemoved(file) {
      this.$emit(
        "asset_reservation_cancelled",
        this.temporaryLog[file.upload.uuid]
      );
      delete this.temporaryLog[file.upload.uuid];
    },
    processFile(file, forceAccept = false) {
      let extension = file.name.split(".");
      if (this.checkValidType(file) || forceAccept) {
        extension = extension.pop();
        extension = "." + extension.toLowerCase();
        const data = this.assetReservation.data;
        data.fileType = (file.captureType != undefined) ? file.captureType : file.type;
        (data.originalFileName = file.name), (data.extension = extension);
        data.fileSize = file.size / 1024 / 1024;

        data.width = this.width;
        data.height = this.height;
        let payload = {
          scope: this.assetReservation.scope,
          data: data
        };

        this.$store
          .dispatch(POPULATE_SIGNED_URL, payload)
          .then(res => {
            this.processingQueue.assetReservationId = res.id;
            this.$refs["ref-" + this.id].setOption("url", res._signedUrl);
            this.$refs["ref-" + this.id].dropzone.processQueue(file);

            if (this.disabledPreviews) {
              let arr = {
                type: this.getFileType(file),
                dataURL: file.dataURL,
                filename: file.name,
                assetReservationId: this.processingQueue.assetReservationId,
                progress: 0
              };
              this.$emit("uploaded_file", arr);
            }
          })
          .catch(() => {
            this.clearErrors();
          });
      } else {
        const files = this.$refs["ref-" + this.id].getQueuedFiles();
        this.$refs["ref-" + this.id].removeFile(files[0]);
        this.$root.$bvToast.toast(
          `The provided file type ${extension} is not supported`,
          {
            title: `${this.$t("TOASTS.FILE_UPLOADED.ERROR_TITLE")}`,
            autoHideDelay: 5000,
            variant: "danger"
          }
        );
        this.error = `The provided file type ${extension} is not supported`;
        this.processingQueue.size--;
        this.processingQueue.processing = false;
      }
    },
    fileUploadingProgress(file, progress) {
      if (this.disabledPreviews) {
        let arr = {
          file: file,
          assetReservationId: this.processingQueue.assetReservationId,
          progress: progress
        };
        this.$emit("update_file_progress", arr);
      }
    },
    fileError(file, message) {
      if (file.status == "error") {
        this.$root.$bvToast.toast(message, {
          title: `${this.$t("TOASTS.FILE_UPLOADED.ERROR_TITLE")}`,
          autoHideDelay: 5000,
          variant: "danger"
        });
        this.error = message;
      }
    },
    fileCancelled(file) {
      if (file.status == "canceled") {
        this.$root.$bvToast.toast(
          `${file.name} ${this.$t("TOASTS.FILE_UPLOADED.CANCELED_MESSAGE")}`,
          {
            title: `${this.$t("TOASTS.FILE_UPLOADED.CANCELED_TITLE")}`,
            autoHideDelay: 5000,
            variant: "warning"
          }
        );
      }
    },
    fileUploaded(file) {
      this.processingQueue.size--;
      this.$emit(
        "asset_reservation_created",
        this.processingQueue.assetReservationId
      );
      this.temporaryLog[
        file.upload.uuid
      ] = this.processingQueue.assetReservationId;
      this.processingQueue.assetReservationId = null;
      this.processingQueue.processing = false;
      this.$root.$bvToast.toast(
        `${this.$t("TOASTS.FILE_UPLOADED.SUCCESS_MESSAGE")}`,
        {
          title: `${this.$t("TOASTS.FILE_UPLOADED.SUCCESS_TITLE")}`,
          autoHideDelay: 5000,
          variant: "success"
        }
      );
    },
    manuallyAddFile: function(file) {
      this.$refs['ref-' + this.id].setOption('maxFiles',null);
      this.$refs["ref-" + this.id].dropzone.addFile(file);
      this.$refs['ref-' + this.id].setOption('maxFiles',this.supportedFileCount);
    }
  },

  watch: {
    processingQueue: {
      handler(val) {
        if (val.size > 0 && val.processing == false) {
          this.processingQueue.processing = true;
          const uploadQueue = this.$refs["ref-" + this.id].getQueuedFiles();
          if (uploadQueue.length > 0) {
            this.processFile(uploadQueue[0]);
          } else {
            this.processingQueue.size--;
            this.processingQueue.processing = false;
          }
        }
      },
      deep: true
    },
    computedDisabled: {
      handler(val) {
        if (val) {
          this.$refs["ref-" + this.id].disable();
        } else {
          this.$refs["ref-" + this.id].enable();
        }
      }
    },
    calculatedMimeTypes: {
      handler(val) {
        this.dropzoneOptions.acceptedFiles = val;
      },
      deep: true
    },
    supportedFileCount: {
      handler(val) {
        this.$refs['ref-' + this.id].setOption('maxFiles',val);
      }
    }
  },

  computed: {
    calculatedMimeTypes: function() {
      if (this.arDropzoneOptions.supportedMimeTypes != undefined) {
        let supportedMimes = [];
        for (const category in this.arDropzoneOptions.supportedMimeTypes) {
          let categoryMimes = this.arDropzoneOptions.supportedMimeTypes[
            category
          ].map(a => a.mime);
          supportedMimes = supportedMimes.concat(categoryMimes);
        }
        supportedMimes = supportedMimes.toString();
        return supportedMimes;
      } else {
        return "";
      }
    },
    calculatedType: function() {
      let translationType = "";

      for (const property in this.arDropzoneOptions.supportedMimeTypes) {
        if (this.arDropzoneOptions.supportedMimeTypes[property].length > 0) {
          if (translationType == "") {
            translationType = property.toUpperCase();
          } else {
            translationType = "GENERIC";
          }
        }
      }

      return translationType;
    },
    displayLabel: function() {
      return GenericInputHelpers.displayLabel(this.label);
    },
    computedLabel: function() {
      return GenericInputHelpers.labelText(this.label, this.model);
    },
    computedDisabled: function() {
      if (this.processingQueue.processing) {
        return false;
      } else {
        return this.dropzoneDisabled;
      }
    },
    supportsScreenRecord: function() {
      if (this.arDropzoneOptions.supportedMimeTypes.video != undefined) {
        let webmEntries = this.arDropzoneOptions.supportedMimeTypes.video.filter(function( mimeObject ) {
          return mimeObject.extension == '.webm';
        });
        if (webmEntries.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    supportsScreenCapture: function() {
      if (this.arDropzoneOptions.supportedMimeTypes.image != undefined) {
        let jpegEntries = this.arDropzoneOptions.supportedMimeTypes.image.filter(function( mimeObject ) {
          return mimeObject.extension == '.jpeg';
        });
        if (jpegEntries.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    multicorderEnabled: function() {
      if (
        (
          this.arDropzoneOptions.supportedMethods.includes(6) && (this.supportsScreenRecord || this.supportsScreenCapture)
        ) ||
        this.arDropzoneOptions.supportedMethods.includes(7)
      ) {
        return true;
      } else {
        return false;
      }
    },
    uploadEnabled: function() {
      if (this.arDropzoneOptions.supportedMethods.includes(5)) {
        return true;
      } else {
        return false;
      }
    },
    supportedFileCount: function() {
      return this.arDropzoneOptions.supportedFileCount;
    }
  }
};
</script>
