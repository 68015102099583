<template>
  <div
    class="row d-flex justify-content-center text-center pt-5 w-100 mx-0"
  >
    <p class="font-weight-bold mb-0">{{ calculatedDropTextOne }}</p>
    <p v-if="uploadEnabled || multicorderEnabled" class="ml-1 mb-0">
      {{ $t("ASSET_RESERVATION.TEXT.DROP_TEXT2") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ArDropzoneText",

  props: {
    fileCount: { type: Number, required: true },
    uploadEnabled: { type: Boolean, required: true },
    multicorderEnabled: { type: Boolean, required: true },
    type: { type: String, required: true }
  },

  computed: {
    calculatedDropTextOne: function() {
      let translationScope =
        this.fileCount == 1 ? "SINGULAR" : "PLURAL";
      let i18nString =
        "ASSET_RESERVATION.TEXT.DROP_TEXT1_" +
        this.type +
        "_" +
        translationScope;

      return `${this.$t(i18nString)}`;
    }
  }
}
</script>