<template>
  <div class="row d-flex justify-content-center">
    <template
      v-for="(types, category) in mimeTypes"
    >
      <i
        :key="category"
        v-if="types.length > 0"
        class="icon-lg px-2"
        :class="typeIcon(category, types)[0]"
        v-b-tooltip.hover
        :title="typeIcon(category, types)[1]"
      >
      </i>
    </template>
  </div>
</template>

<script>
export default {
  name: "ArDropzoneIcons",

  props: {
    mimeTypes: { type: Object, required: true },
    mimeSizes: { type: Object, required: true }
  },

  methods: {
    typeIcon(category, types) {
      let extensions = types.map(a => a.extension);
      extensions = extensions.toString();
      extensions = extensions.replace(/\./g, " ");
      let iconArray = [];
      let icon = null;
      let fileTypes = extensions;
      let fileSize = `${this.mimeSizes[category]}mb`
      let tooltip = fileTypes + " (" + fileSize + ")";

      switch (category) {
        case "image":
          icon = "fas fa-camera";
          break;
        case "video":
          icon = "fas fa-video";
          break;
        case "text":
          icon = "fas fa-file-csv";
          break;
        case "audio":
          icon = "fas fa-music";
          break;
        case "application":
          icon = "fas fa-file-pdf";
          break;
      }

      iconArray.push(icon);
      iconArray.push(tooltip);

      return iconArray;
    }
  }
}
</script>