<template>
  <div class="container text-center px-4">
    <div class="row text-center">
      <div class="col-12 p-0">
        <div class="videobox">
          <Multicorder
            :video-source="computedVideoSource"
            @error="onError"
            @cameras="onCameras"
            @video-live="onVideoLive"
            @view-change="onViewChange"
            @new-recording="onNewRecording"
            @delete-recording="onDeleteRecording"
            @player-loaded="onPlayerLoaded"
            ref="multicorder"
            style="padding:1px !important"
            :videoTypes="supportedVideoTypes"
            :recorderMode="recorderMode"
          />
        </div>

        <div
          v-if="computedVideoSource"
          align="right"
          background-color="black"
          class="grey--text videobox pb-2"
          id="device-info"
        >
          {{ computedVideoSource.text }}
          <a href="javascript:void(0)" @click="videoClose" class="mx-2">
            <i class="fa far fa-times-circle mt-1 mr-1"></i>
          </a>
        </div>

        <div
          v-if="!computedVideoSource"
          align="right"
          background-color="black"
          class="grey--text videobox pb-2"
          id="mobile-close"
        >
          <a href="javascript:void(0)" @click="$emit('close-modal')" class="mx-2">
            <i class="fa far fa-times-circle mt-1 mr-1"></i>
          </a>
        </div>

        <div id="multicorder-controls">
          <DeviceInput
            v-if="!computedVideoSource"
            :videoSource="$v.videoSource"
            :view="view"
            :videoSourceList="videoSourceList"
          >
          </DeviceInput>

          <PreRecord
            v-if="view == 'video' && computedVideoSource != null && controls == 'liveVideo'"
            :isMuted="isMuted"
            :recorderSettings="recorderSettings"
            @toggleMuted="toggleMuted"
            @videoRecord="videoRecord"
            @videoSnapshot="videoSnapshot($event)"
          >
          </PreRecord>

          <LiveRecord
            v-if="view == 'video' && computedVideoSource != null && controls == 'recordingVideo'"
            :isMuted="isMuted"
            :isPaused="isPaused"
            @videoStopRecording="videoStopRecording"
            @toggleMuted="toggleMuted"
            @pause="togglePause"
          >
          </LiveRecord>

          <PostRecord
            v-if="view == 'videoPlayer' && computedVideoSource != null"
            :isPlayerMuted="isPlayerMuted"
            @togglePlayerMuted="togglePlayerMuted"
            @deleteRecording="deleteRecording"
            @downloadRecording="downloadRecording"
            @playRecording="playRecording"
            @completeRecording="completeRecording"
          >
          </PostRecord>

          <PostCapture
            v-if="view == 'snapshot'"
            @closeSnapshot="closeSnapshot"
            @snapshotDownload="snapshotDownload"
            @completeCapture="completeCapture"
          >
          </PostCapture>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { Multicorder } from "multicorder";

import DeviceInput from "./multicorder-controls/DeviceInput";
import PreRecord from "./multicorder-controls/PreRecord";
import LiveRecord from "./multicorder-controls/LiveRecord";
import PostRecord from "./multicorder-controls/PostRecord";
import PostCapture from "./multicorder-controls/PostCapture";

export default {
  mixins: [validationMixin],

  name: "MulticorderUI",

  props: {
    recorderMode: {
      type: String,
      default: "single",
    },
    recorderSettings: { type: Object, required: true }
  },

  data() {
    return {
      controls: null,
      videoSource: null,
      videoSourceList: [],
      isPaused: false,
      isPlayerPaused: false,
      isMuted: false,
      isPlayerMuted: false,
      view: "video",
      recordings: [], // local sparsed list of recording data
    };
  },

  validations: {
    videoSource: {}
  },

  components: {
    Multicorder,
    DeviceInput,
    PreRecord,
    LiveRecord,
    PostRecord,
    PostCapture
  },

  methods: {
    onError(error) {
      /*eslint-disable */
      console.log("Error emitted", error);
      /*eslint-enable */
    },
    onCameras(cameras) {
      /**
       * We are implementing a `multicorder` with camera and screen support.
       * We need to create a list that groups the items for a `v-select` component.
       * We use the `listFromCameras` helper function provided by the component.
       * The Multicorder component maintains a list of `cameras` if we need them independently.
       */
      this.videoSourceList = this.$refs.multicorder.listFromCameras(cameras);
    },
    onVideoLive() {
      this.controls = "recordingVideo";
      this.$refs.multicorder.startVideoRecording();
    },
    onViewChange(view) {
      this.view = view;
    },
    onNewRecording(recording) {
      this.recordings.push(recording);
      if (this.recorderMode == "single") {
        // Load the video into the player and force disposition
        // this.view = "videoPlayer";
        this.loadRecording(0);
      }
    },
    onDeleteRecording(index) {
      this.recordings.splice(index, 1);
      if (this.recorderMode == "single") {
        this.controls = "liveVideo"  
      }
    },
    onPlayerLoaded() {
      //this.playRecording();
    },
    videoRecord() {
      this.controls = "recordingVideo";
      this.$refs.multicorder.startVideoRecording();
    },
    videoSnapshot(fromView) {
      this.$refs.multicorder.videoSnapshot(fromView);
    },
    videoClose() {
      this.$refs.multicorder.stopVideo();
      this.view = "video";
      this.controls = "liveVideo";
      this.videoSource = null;
    },
    videoStopRecording() {
      this.$refs.multicorder.stopRecording();
      // resume the video, minus recording
      this.resume();
    },
    resume() {
      this.isPaused = false;
      this.$refs.multicorder.resume();
    },
    togglePause() {
      if (this.isPaused) {
        this.isPaused = false;
        this.$refs.multicorder.resume();
      } else {
        this.isPaused = true;
        this.$refs.multicorder.pause();
      }
    },
    closeSnapshot() {
      this.$refs.multicorder.closeSnapshot();
      this.view = "video"
    },
    snapshotDownload() {
      this.$refs.multicorder.downloadSnapshot();
    },
    downloadRecording(index) {
      if(this.recorderMode === 'single') {
        index = 0;
      }
      this.$refs.multicorder.downloadRecording(index);
    },
    deleteRecording(index) {
      if(this.recorderMode === 'single') {
        index = 0;
      }
      this.$refs.multicorder.deleteRecording(index);
    },
    async loadRecording(index) {
      await this.$refs.multicorder.loadRecording(index);
    },
    playRecording() {
      this.isPlayerPaused = false;
      this.$refs.multicorder.playRecording();
    },
    pausePlayer() {
      this.isPlayerPaused = true;
      this.$refs.multicorder.pausePlayer();
    },
    resumePlayer() {
      this.isPlayerPaused = false;
      this.$refs.multicorder.resumePlayer();
    },
    deletePlayerRecording() {
      this.$refs.multicorder.deletePlayerRecording();
    },
    closePlayer() {
      this.$refs.multicorder.closePlayer();
    },
    toggleMuted() {
      this.isMuted = !this.isMuted;
    },
    togglePlayerMuted() {
      this.isPlayerMuted = !this.isPlayerMuted;
    },
    completeCapture: function() {

      if (!this.recorderSettings.capture) {
        window.alert("Error - Method not supported");
        return;
      }

      var arr = this.$refs.multicorder.snapshot.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      let file = new File([u8arr], "capture.jpeg", {type:mime});

      file.upload = {
        chunked: false,
        totalChunkedCount: 1,
        filename: "capture.jpeg",
        progress: 0,
        total: file.size,
        bytesSent: 0
      };
      file.status = "processing";
      file.accepted = true;
      file.processing = true
      file.captureType = "image/jpeg";
      file.xhr = {};

      this.$emit("addFile", file);
    },
    completeRecording: function() {

      if (!this.recorderSettings.record) {
        window.alert("Error - Method not supported");
        return;
      }

      var recording = this.recordings[0];
      var blob = this.$refs.multicorder.recordings[0];
      var file = new File([blob], "capture.webm", { type: "video/webm" });
      var uuid = recording.name.replace("clip-","").replace(".webm","");

      file.upload = {
        uuid: uuid,
        chunked: false,
        totalChunkedCount: 1,
        filename: "capture.webm",
        progress: 0,
        total: recording.size,
        bytesSent: 0
      };
      file.status = "processing";
      file.accepted = true;
      file.processing = true
      file.captureType = "video/webm";
      file.xhr = {};

      this.$emit("addFile", file);
    } 
  },
  computed: {
    computedVideoSource: function() {
      let selectedVideoSource = this.videoSource;

      if ( selectedVideoSource ) {
        return this.videoSourceList.filter(function( videoSource ) {
          return videoSource.value == selectedVideoSource;
        })[0];
      } else {
        return null;
      }
    },
    supportedVideoTypes: function() {
      let types = [];
      if (this.recorderSettings.methods.includes(6) && this.recorderSettings.record == true) {
        types.push("screen");
      }
      if (this.recorderSettings.methods.includes(7) && (this.recorderSettings.record == true || this.recorderSettings.capture == true)) {
        types.push("camera");
      }
      return types;
    }
  }
};
</script>

<style scoped>
.videobox {
  background-color: black;
}
</style>
