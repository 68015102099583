import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_SIGNED_URL = "populateSignedUrl";
export const RETURN_SUPPORTED_ASSET_TYPE = "returnSupportedAssetType";
export const CLEAR_SIGNED_URL = "clearSignedUrl";
export const CLEAR_ASSET_ERRORS = "clearAssetErrors";

// mutation types
export const SET_SIGNED_URL = "setSignedUrl";
export const RESET_SIGNED_URL = "resetSignedUrl";
export const SET_ASSET_ERRORS = "setAssetErrors";
export const RESET_ASSET_ERRORS = "resetAssetErrors";

const state = {
  signed_url: {},
  errors: {}
};

const getters = {
  signedUrl(state) {
    return state.signed_url;
  },
  assetErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_SIGNED_URL](context, payload) {
    return ApiService.post("api/v1/assets/" + payload.scope, payload.data)
      .then(res => {
        const signedUrl = res.data.data;
        context.commit(SET_SIGNED_URL, signedUrl);
        return signedUrl;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_ASSET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [RETURN_SUPPORTED_ASSET_TYPE](context, payload) {
    let route =
      "api/v1/" + payload.scope + "/" + payload.directory + "/supported_types";
    if (payload.activityId !== undefined) {
      route = route + "?activity_id=" + payload.activityId;
    }
    return ApiService.get(route)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_ASSET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_SIGNED_URL](context) {
    context.commit(RESET_SIGNED_URL);
  },
  [CLEAR_ASSET_ERRORS](context) {
    context.commit(RESET_ASSET_ERRORS);
  }
};

const mutations = {
  [SET_SIGNED_URL](state, signedUrl) {
    state.signed_url = signedUrl;
  },
  [RESET_SIGNED_URL](state) {
    state.signed_url = {};
  },
  [SET_ASSET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_ASSET_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
