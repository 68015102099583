class GenericInputHelpers {

  static displayLabel(label) {
    return (label.length > 0) ? true : false;
  }

  static labelText(label, model) {
    if (label.length > 0) {
      if (model.$params != undefined) {
        if (model.$params.hasOwnProperty("required")) {
          return label + "*";
        } else {
          return label;
        }
      } else {
        return label;
      }
    } else {
      return null;
    }
  }

}

export default GenericInputHelpers;