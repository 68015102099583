<template>
  <div class="row d-flex justify-content-between mt-5 pt-0 mx-3">
    <div class="col-3 pt-2 text-left">
      <a
        href="javascript:void(0)"
        @click="$emit('toggleMuted')"
        v-b-tooltip.hover.bottom
        :title="calculatedMuteTooltip"
      >
        <i
          class="fa icon-lg"
          :class="{ 'fas fa-microphone text-muted text-hover-danger pl-2 pr-2' : !isMuted, 'fas fa-microphone-slash text-danger text-hover-muted pr-1' : isMuted }"
        >
        </i>
      </a>
    </div>

    <div class="d-flex justify-content-center col-6 text-center">
      <a
        href="javascript:void(0)"
        @click="$emit('videoStopRecording')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.STOP')"
      >
        <i
          class="fa fas fa-stop-circle text-danger px-2"
          style="font-size:3rem !important"
        >
        </i>
      </a>

      <a
        href="javascript:void(0)"
        @click="$emit('pause')"
        v-b-tooltip.hover.bottom
        :title="$t('MULTICORDER.CONTROLS.PAUSE')"
      >
        <i
          class="fa fas fa-pause-circle px-2"
          :class="{ 'text-danger text-hover-muted' : isPaused, 'text-muted text-hover-danger' : !isPaused }"
          style="font-size:3rem !important"
        >
        </i>
      </a>      
    </div>

    <div class="col-3 text-right"></div>
  </div>
</template>

<script>
export default {
  name: "LiveRecord",

  props: {
    isMuted: { type: Boolean, required: true },
    isPaused: { type: Boolean, required: true }
  },

  computed: {
    calculatedMuteTooltip: function() {
      return (this.isMuted) ? `${this.$t("MULTICORDER.CONTROLS.UNMUTE")}` : `${this.$t("MULTICORDER.CONTROLS.MUTE")}`
    }
  }
}
</script>